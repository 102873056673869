import React from "react";
import { Paper } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function Goods(props) {
    const { goods } = props;

    return (
        <Paper elevation={10}>
            <div style={{ padding: "10px", borderRadius: "5px", backgroundColor: "#F3F4F7", overflow: "hidden" }}>
                <TableContainer>
                    <Table aria-label="caption table" size="small">
                        <TableHead>
                            <TableRow key="0">
                                <TableCell width="100" align="right"></TableCell>
                                <TableCell width="100">Units</TableCell>
                                <TableCell width="200">Description</TableCell>
                                <TableCell width="100" align="right">Weight</TableCell>
                                <TableCell width="100" align="right">Ldm</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {(goods) ? (goods.map((row) => (
                                <TableRow key={row.lineSeqNo}>
                                    <TableCell align="right">{row.lineUnits}</TableCell>
                                    <TableCell>{row.unitdescr}</TableCell>
                                    <TableCell>{row.gooddescr}</TableCell>
                                    <TableCell align="right">{row.lineWeight}</TableCell>
                                    <TableCell align="right">{row.lineMeters}</TableCell>
                                </TableRow>
                            )))
                                : ""}
                        </TableBody>

                    </Table>
                </TableContainer>
            </div>
        </Paper>
    );
}
